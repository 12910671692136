<template>
  <main class="pt2020">
    <b-row class="main-section kh-bg-secondary">
      <b-container>
        <b-row class="mb-5" no-gutters>
          <b-img class="mr-4" src="@/assets/img/pt2020-01.svg" height="64"/>
          <b-img class="mr-4" src="@/assets/img/pt2020-02.svg" height="64"/>
          <b-img class="mr-4" src="@/assets/img/pt2020-03.svg" height="64"/>
        </b-row>
        <b-link :to="{name: 'project-sheet-inter'}">View Kinetikos International</b-link>
      </b-container>
    </b-row>

    <b-row class="main-section">
      <b-container>
        <b-row class="mb-5 mb-md-3">
          <b-col md="3" class="h5 font-weight-bold">Designação do projeto</b-col>
          <b-col>Kinetikos4PD</b-col>
        </b-row>

        <b-row class="mb-5 mb-md-3">
          <b-col md="3" class="h5 font-weight-bold">Código do projeto</b-col>
          <b-col>CENTRO-01-0247-FEDER-045271</b-col>
        </b-row>

        <b-row class="mb-5 mb-md-3">
          <b-col md="3" class="h5 font-weight-bold">Objetivo principal</b-col>
          <b-col>Reforçar a investigação, o desenvolvimento tecnológico e a inovação</b-col>
        </b-row>

        <b-row class="mb-5 mb-md-3">
          <b-col md="3" class="h5 font-weight-bold">Região de intervenção</b-col>
          <b-col>Centro</b-col>
        </b-row>

        <b-row class="mb-5 mb-md-3">
          <b-col md="3" class="h5 font-weight-bold">Entidade beneficiária</b-col>
          <b-col>KINETIKOS - DRIVEN SOLUTIONS LDA</b-col>
        </b-row>

        <b-row class="mb-5 mb-md-3">
          <b-col md="3" class="h5 font-weight-bold">Data da aprovação</b-col>
          <b-col>16-12-2019</b-col>
        </b-row>

        <b-row class="mb-5 mb-md-3">
          <b-col md="3" class="h5 font-weight-bold">Data de início</b-col>
          <b-col>01-04-2020</b-col>
        </b-row>

        <b-row class="mb-5 mb-md-3">
          <b-col md="3" class="h5 font-weight-bold">Data de conclusão</b-col>
          <b-col>30-06-2022</b-col>
        </b-row>

        <b-row class="mb-5 mb-md-3">
          <b-col md="3" class="h5 font-weight-bold">Custo total elegível</b-col>
          <b-col>365.847,82 €</b-col>
        </b-row>

        <b-row>
          <b-col md="3" class="h5 font-weight-bold">Apoio financeiro da União Europeia</b-col>
          <b-col>280.394,14 €</b-col>
        </b-row>
      </b-container>
    </b-row>

    <b-row class="main-section kh-bg-secondary pt2020-section">
      <b-container>
        <b-row>
          <b-col md="8">
            <h2>Síntese do projeto</h2>

            <h4 class="mt-5">Objetivos</h4>
            <p>O projeto tem como principais objetivos:</p>
            <ul>
              <li>A criaçao de uma soluçao centrada na pessoa e que incorpora a dimensao biopsicossocial para o tratamento e gestao da doença de Parkinson;</li>
              <li>Estabelecer canais de comunicaçao entre todos os envolvidos (profissionais de saude, cuidadores informais e pacientes) e criar um fluxo bidirecional de informaçao relevante entre os mesmos;</li>
              <li>Permitir avançar a fronteira do conhecimento, atraves do mapeamento multidimensional objetivo da doença de Parkinson e abrindo, assim, caminho para um conhecimento mais aprofundado sobre a mesma;</li>
            </ul>

            <h4 class="mt-5">Atividades e Resultados esperados</h4>
            <p>Resumidamente, as atividades de investigação que a Kinetikos irá desenvolver são:</p>
            <ul>
              <li>Investigação preliminar e conceptualização</li>
              <li>Execução do Protótipo Laboratorial</li>
              <li>Validação do protótipo em ambiente relevante</li>
              <li>Validação em ambiente real</li>
            </ul>

            <p class="mt-5">Destas atividades resultará uma solução digital com potencial comercial (Kinetikos4PD).</p>
          </b-col>
        </b-row>
      </b-container>
    </b-row>
  </main>
</template>

<script>
export default {
  name: 'project-sheet-inter'
}
</script>
